import { Routes, Route, useParams } from "react-router-dom";
import axios from "axios";

import "./App.css";
import { Col, Image, Row, Table, Tabs, Timeline } from "antd";
import { useEffect, useState } from "react";
import {
  UserOutlined,
  MonitorOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const { REACT_APP_API_URL: apiServer, REACT_APP_IMG_URL: imageServer } =
  process.env;

const { TabPane } = Tabs;

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="qr/:qrCode" element={<Detail />} />
      </Routes>
    </div>
  );
}

function Home() {
  return (
    <>
      <h1>Welcome!</h1>
    </>
  );
}
interface Info {
  asset_name: string;
  qr_code: string;
  type_text: string;
  price: string;
  owner_at: string;
  sponsor_text: string;
  comment: string;
  status: string;
  owner_type: string;
  sponsor_type: string;
  owner_name: string;
  asset_type: string;
  plate_number: string;
}

interface History {
  id: string;
  status: number;
  title: string;
  description: any;
  duration_from: string;
  duration_to?: string;
  transfer_to: string;
  status_text: string;
  from?: string;
}

function Detail() {
  const { qrCode } = useParams<"qrCode">();
  const [imageVisible, setImageVisible] = useState(false);
  const [info, setInfo] = useState<Info | null>(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [histories, setHistories] = useState<History[]>([]);

  const callback = (key: string) => {
    console.log(key);
  };

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      width: "200px",
      render: (text: string) => (
        <label className="font-weight-bold">{text}</label>
      ),
    },
    {
      title: "value",
      dataIndex: "value",
      render: (text: string) => {
        console.log("text", text);

        if (text) {
          return <span>{text}</span>;
        }

        return <span className="font-gray">មិនបានបញ្ជូល</span>;
      },
    },
  ];

  let data = [
    {
      key: "name",
      name: "ឈ្មោះទ្រព្យ/ម៉ាក",
      value: info?.asset_name,
    },
    {
      key: "owner_name",
      name: "ឈ្មោះម្ចាស់",
      value: info?.owner_name,
    },

    {
      key: "qr_code",
      name: "QR Code",
      value: info?.qr_code,
    },
    {
      key: "asset_type",
      name: "ប្រភេទទ្រព្យ",
      value: info?.asset_type,
    },
  ];

  let data1: ConcatArray<{
    key: string;
    name: string;
    value: string | undefined;
  }> = [];

  if (info?.plate_number) {
    data1 = [
      {
        key: "plate_number",
        name: "ផ្លាកលេខ",
        value: info?.plate_number,
      },
    ];
  }
  const data2 = [
    {
      key: "owner_type",
      name: "ជាទ្រព្យ",
      value: info?.owner_type,
    },
    {
      key: "status",
      name: "ស្ថានភាព",
      value: info?.status,
    },
    {
      key: "price",
      name: "តំលៃ",
      value: info?.price,
    },
    {
      key: "owner_at",
      name: "ឆ្នាំកាន់កាប់ទ្រព្យ",
      value: info?.owner_at,
    },
    {
      key: "sponsor_text",
      name: "ម្ចាស់ជំនួយ",
      value: info?.sponsor_text,
    },
    {
      key: "comment",
      name: "មតិយោបល",
      value: info?.comment,
    },
  ];

  data = data.concat(data1, data2);

  useEffect(() => {
    axios.get(`${apiServer}/api/qr/${qrCode}`).then((res) => {
      const responseData = res?.data?.data;
      setInfo(responseData?.info);
      setImageUrl(responseData?.image_url);
      setHistories(responseData?.histories);
    });
  }, []);

  const renderTimelineLabel = (history: any) => {
    if (history?.status === 0) {
      return (
        <>
          <div className="color-red">{history?.time}</div>
          <div className="color-red">{history?.status_text}</div>
        </>
      );
    }
    return <div className="color-green">{history?.time}</div>;
  };

  const firstRecords = histories[histories.length - 1];
  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        md={{ span: 12, offset: 6 }}
        xl={{ span: 10, offset: 7 }}
        style={{ marginTop: 30 }}
      >
        <Tabs defaultActiveKey="1" centered onChange={callback}>
          <TabPane tab="ពត៌មានទូទៅ" key="1">
            <Table
              columns={columns}
              showHeader={false}
              dataSource={data}
              size="middle"
              pagination={false}
            />
          </TabPane>
          <TabPane tab="រូបភាព" key="2">
            <Image.PreviewGroup
              preview={{
                visible: imageVisible,
                onVisibleChange: (vis) => setImageVisible(vis),
              }}
            >
              <div className="image-container">
                {imageUrl.map((image, index) => {
                  return (
                    <div key={image}>
                      <Image
                        style={{
                          objectFit: "cover",
                          // borderRadius: 40,
                        }}
                        width={120}
                        height={120}
                        src={imageServer + image + "?dim=400"}
                        preview={{
                          src: imageServer + image + "?dim=1080",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </Image.PreviewGroup>
          </TabPane>
          <TabPane tab="ប្រវត្តិនៃការប្រើប្រាស់" key="3">
            <Timeline mode="left" className="mt-30">
              {histories?.map((history) => {
                return (
                  <Timeline.Item
                    label={renderTimelineLabel(history)}
                    key={history?.id}
                    color={history?.status === 1 ? "green" : "red"}
                  >
                    <div>
                      <div className="list-item">
                        <div className="icon-container">
                          <UserOutlined />
                        </div>
                        {history?.title}
                      </div>
                      <div className="list-item">
                        <div className="icon-container">
                          <MonitorOutlined />
                        </div>
                        {history?.description?.status_text}
                      </div>
                      <div className="list-item">
                        <div className="icon-container">
                          <ClockCircleOutlined />
                        </div>
                        {history?.description?.duration}
                      </div>
                    </div>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}

export default App;
